import React from 'react'

import { getPlanBySlug } from '@smarty-nx/contentful'
import cn from 'classnames'
import Countdown from 'react-countdown'

import { countdownBannerContent } from './CountdownBanner.content'
import classes from './CountdownBanner.module.scss'
import { CountdownBannerProps } from './CountdownBanner.types'
import { timeFormat } from '../../utils/timeFormat'

const CountdownBanner = ({
  planName,
  planSlug,
  voucherExpiryDate,
  discountedPrice,
  originalPrice,
}: CountdownBannerProps) => {
  const planMeta = getPlanBySlug(planSlug)
  if (!planMeta) {
    return null
  }

  const { slug, planColor } = planMeta

  return (
    <a className={classes.root} href={`/order/${slug}`}>
      <div
        className={cn(
          classes.wrapper,
          classes[`backgroundColour${planColor?.theme.replace('/', '')}`]
        )}
      >
        <div
          className={cn(classes.countdownColumnFlex, classes.countdownColumn)}
        >
          <p className={cn(classes.text, classes.title)}>{planName}GB</p>
          <div className={classes.countdownColumn}>
            <p
              className={cn(
                classes.smallTextPriceCrossed,
                classes.smallText,
                classes.smallTextPrice
              )}
            >
              {originalPrice}GB
            </p>
            <p className={cn(classes.smallTextPrice, classes.smallText)}>
              £{discountedPrice}
            </p>
          </div>
        </div>
        <div className={cn(classes.countdownColumn, classes.withNumbers)}>
          {countdownBannerContent.durations.map((d) => (
            <div className={classes.time} key={d.time}>
              <Countdown
                date={voucherExpiryDate}
                renderer={(props) => (
                  <div>
                    <div
                      className={cn(
                        classes.countdownColumnNumber,
                        classes.countdownColumn
                      )}
                    >
                      <p className={cn(classes.textNumber, classes.text)}>
                        {timeFormat(props[d.time])[0]}{' '}
                      </p>
                      <p className={cn(classes.textNumber, classes.text)}>
                        {timeFormat(props[d.time])[1]}{' '}
                      </p>
                    </div>

                    <p className={cn(classes.smallText, classes.timeText)}>
                      {d.text_to_display}
                    </p>
                  </div>
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </a>
  )
}

export default CountdownBanner
