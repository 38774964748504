import React from 'react'

import { Button } from '@smarty-nx/ds'
import cn from 'classnames'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import classes from './social-tariff.module.scss'
import EllipseCutOut from '../../components/EllipseCutOut'
import PageHead from '../../components/PageHead'
import { Header2 } from '../../components/styles/FontSize'
import SiteContainer from '../../components/styles/SiteContainer'
import Spacer from '../../components/styles/Spacer'
import withApp from '../../components/withApp/withApp'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import { useIsAuthenticated } from '../../hooks'
import AllPlans from '../../modular-system/AllPlans'
import FaqSection from '../../modular-system/FaqSection'
import piggyBank from '../../static/images/refurbished-phones-page/piggybank.svg'
import calendar from '../../static/images/social-tariff/calendar.svg'
import checkMark from '../../static/images/social-tariff/checkmark.svg'
import noContract from '../../static/images/social-tariff/no-contract.svg'
import simInHand from '../../static/images/social-tariff/sim-in-hand.svg'
import socialTariff from '../../static/images/social-tariff/social-tariff.svg'

const SocialTariff = ({
  data: {
    contentfulPageSection: { header, items, link },
  },
}) => {
  const isAuthenticated = useIsAuthenticated()

  function PromiseGenesys(command, action, payload) {
    return new Promise((resolve, reject) => {
      window.Genesys(
        command,
        action,
        payload,
        (response) => {
          setTimeout(() => resolve(response), 500)
        },
        (err) => {
          console.error(err)
          reject(err)
        }
      )
    })
  }

  const sendMessageGenesys = async () => {
    await PromiseGenesys('command', 'database.set', {
      messaging: {
        customAttributes: {
          chatURL: document.location.origin + document.location.pathname,
          isAuthenticated: localStorage.getItem('isAuthenticated'),
          entryPoint: 'website-socialtariff',
        },
      },
    })
    await PromiseGenesys('command', 'Messenger.open', {})
    await PromiseGenesys('command', 'MessagingService.sendMessage', {
      message: `I'm already with SMARTY but I want to switch to Social Tariff`,
    })
  }

  return (
    <div className={classes.socialTariff}>
      <div className={classes.socialTariffBanner}>
        <div className={classes.socialTariffBannerContent}>
          <img src={simInHand} className={classes.simInHandIcon} alt="" />
          <h1>SMARTY Social Tariff</h1>
          <p>
            Staying in touch is an essential part of life, which is why we’re
            launching the SMARTY Social Tariff. A low-cost unlimited plan for
            those who need it most, available for new and existing customers on
            eligible benefits.
          </p>
          <div className={classes.socialBenefits}>
            <div className={classes.item}>
              <img src={checkMark} alt="" />
              <span>Fast eligibility checks with no credit checks</span>
            </div>
            <div className={classes.item}>
              <img src={piggyBank} alt="" />
              <span>Affordable pricing to help you stay connected</span>
            </div>
            <div className={classes.item}>
              <img src={calendar} alt="" />
              <span>
                Once accepted, keep the plan for as long as you`&apos;re
                eligible
              </span>
            </div>
            <div className={classes.item}>
              <img src={noContract} alt="" />
              <span>Ultimate flexibility - 1 month rolling plan</span>
            </div>
          </div>

          {isAuthenticated ? (
            <div className={classes.actions}>
              <Button
                data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                data-item-name=""
                data-testid="get-smarty-social-tariff-button"
              >
                Get SMARTY Social Tariff
              </Button>
            </div>
          ) : (
            <>
              <div className={cn(classes.actions, classes.twoColumns)}>
                <Button
                  as={(props) => (
                    <Link
                      {...props}
                      to="/sim-in-hand"
                      data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                      data-item-name="go-to-social-tariff-check"
                      data-testid="social-tariff-check-button"
                    >
                      I’m new to SMARTY
                    </Link>
                  )}
                />

                <Button
                  data-testid="social-tariff-with-smarty-button"
                  level="secondary"
                  onClick={() => sendMessageGenesys()}
                >
                  I’m already with SMARTY
                </Button>
              </div>

              <div className={classes.links}>
                <Button
                  level="tertiary"
                  as={(props) => (
                    <a
                      {...props}
                      href="https://www.three.co.uk/vulnerability/financial-support"
                      data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                      data-item-name=""
                      data-testid=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      I’m already with Three
                    </a>
                  )}
                />
              </div>
            </>
          )}
        </div>

        <EllipseCutOut className={classes.cutOut} />
      </div>

      <SiteContainer>
        <div className={classes.pricingSection}>
          <div className={classes.left}>
            <img src={socialTariff} alt="" />
          </div>

          <div className={classes.right}>
            <Header2>SMARTY Social Tariff Pricing</Header2>
            <p>
              As a SMARTY Social Tariff customer you get unlimited data for just
              £12 a month. As standard, you’ll also have unlimited UK calls and
              texts, 5G at no extra cost and EU Roaming (up to 12GB) included,
              all on a flexible 1 month plan with no annual price rises.
            </p>
          </div>
        </div>
      </SiteContainer>
      <SiteContainer>
        <div className={classes.planIncludes}>
          <AllPlans
            isSocialTariff
            headerTestId="all-plans-include-title"
            listTestId="all-plans-include-list-item"
            iconTestId="all-plans-check-icon"
            headerClassName={classes.planIncludesHeader}
          />
        </div>
        <Spacer />
      </SiteContainer>
      <div className={classes.darkestGreyBlock}>
        <SiteContainer>
          <Spacer />
          <FaqSection header={header} items={items} link={link} />
          <Spacer />
        </SiteContainer>
      </div>
    </div>
  )
}

SocialTariff.propTypes = {
  data: PropTypes.object,
}

export default withApp(SocialTariff)

export const Head = () => (
  <PageHead
    title="Social Tariffs SIM plans with no contract, no credit check."
    description="Get discounted SIM deals, 'Social Tariffs' for those on certain benefits. SMARTY Mobile, Simple honest Mobile. "
  />
)

export const query = graphql`
  query {
    contentfulPageSection(sectionName: { eq: "st-faq-list" }) {
      ...FaqSection
    }
  }
`
